<template>

  <b-card 
    title="Inventario por seriales"
    sub-title="Ingreso de seriales">

    <b-row>
      <b-col cols="12">

        <inventory-products-card :stowage-id="stowageId"/>

      </b-col>
    </b-row>

  </b-card>
  
</template>

<script>
import router from '@/router'
import { BRow, BCol, BTabs, BTab, BCard } from 'bootstrap-vue'
import InventoryProductsCard from './InventoryProductsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,

    InventoryProductsCard,
  },
  data() {
    return {
      stowageId: null,
    }
  },
  created() {
    this.stowageId = router.currentRoute.params.stowage_id
    console.log(this.stowageId)
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>